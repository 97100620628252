<template>
  <div class="daily-mobile">
    <transition-group name="slide">
      <template v-if="!showDetail">
        <common key="common" topStyle="daily-top daily-auto-height" mainStyle="daily-main">
          <nav>
            <ul class="flex-w tasktype-ul">
              <li
                v-for="(item, index) in funList"
                :key="index"
                :class="{ aliveNav: aliveKey === item, 'tasktype-li': true }"
                @click="handleNav(item)"
              >
                <div :class="['broder-line']">{{ item }}</div>
              </li>
            </ul>
            <div class="flex-w screen-search">
              <input class="search" placeholder="搜索" v-model="keyword" />
            </div>
          </nav>
          <template v-slot:headers>
            <div @click="back" class="back-img" />
            <div style="margin-left: 4.1rem; margin-right: auto"></div>
            <div class="title">{{ title }}</div>
            <div style="margin-left: auto; margin-right: 4.1rem" />
            <div v-if="type == 'dailyBox'" class="el-icon-circle-plus-outline img img-add" @click="handleAddTask"></div>
            <div v-else style="margin-right: 4.1rem"></div>
          </template>
        </common>
        <div key="ul" class="flex-w result">
          <ul class="task-ul">
            <li class="flex-w task-li" v-for="task in taskLis" :key="task.id" @click="displayDetail(task.id)">
              <div
                :style="{ backgroundColor: xIco[task.clean_status].bgColor, color: xIco[task.clean_status].color }"
                class="flex-w status-ico"
              >
                <img :src="xIco[task.clean_status].ico" alt />
                <span>{{ xIco[task.clean_status].str }}</span>
              </div>
              <div class="title">{{ task.title }}</div>
              <img v-if="task.task_id === '-1'" class="ico" src="~@/assets/image/icon-daily/icon_gr_16.png" />
              <img v-else class="ico" src="~@/assets/image/icon-daily/icon_sjx_16.png" />
            </li>
          </ul>
        </div>
      </template>
      <template v-if="showDetail">
        <dailyDetail key="dailyDetail" @back="detailBack" v-if="type == 'dailyBox'" :para="{ id: taskId }" />
        <taskDetail key="taskDetail" @back="detailBack" v-else-if="type == 'taskMy'" :para="{ id: taskId }" />
      </template>
    </transition-group>
    <transition name="my-sheet">
      <div class="cover sheet-cover" style="z-index: 10000" v-show="isSelecting" @click.self="isSelecting = false">
        <div class="main-frame">
          <div class="flex-w head-nav">
            <span class="nav" @click.stop="isSelecting = false">取消</span>
            <span class="title">新建任务</span>
            <span class="nav" @click="addDailyTask">完成</span>
          </div>
          <div class="task-form">
            <input type="text" placeholder="请输入任务标题" v-model="nd_title" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { xIco } from '@/common/js';
import dailyDetail from '@/components/mobile/daily/dailyDetail';
import taskDetail from '@/components/mobile/progress/taskDetail';
import { getDailyBoxLis, getDailyMyself, postDailyBoxNew } from '@/service/help';
export default {
  props: ['type'],
  data() {
    return {
      xIco,
      keyword: '',
      api: '',
      title: '',
      taskData: [],
      funList: ['进行中', '已归档'],
      aliveKey: '进行中',
      showDetail: false,
      taskId: '0',
      taskIdLis: [],
      isSelecting: '',
      nd_title: '',
    };
  },
  components: {
    dailyDetail,
    taskDetail,
  },
  computed: {
    taskLis() {
      let data = this.taskData[this.aliveKey] || [];
      return data.filter(v => {
        return v.title.indexOf(this.keyword) >= 0;
      });
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    addDailyTask() {
      postDailyBoxNew({ status: '0', title: this.nd_title }).then(res => {
        console.log();
        if (res.data.success) {
          this.$toast.success('添加成功');
          this.getData();
        }
        this.isSelecting = false;
      });
    },
    handleAddTask() {
      this.isSelecting = true;
    },
    detailBack(id) {
      if (id) {
        this.showDetail = false;
        this.$nextTick(() => {
          this.displayDetail(id);
        });
        return;
      }
      console.log('detailBack', this.taskIdLis);
      this.taskIdLis.pop();
      this.showDetail = false;
      if (this.taskIdLis.length > 0) {
        this.$nextTick(() => {
          this.displayDetail(this.taskIdLis.pop());
        });
      }
    },
    back() {
      //不做任何改变
      this.$emit('back');
    },
    displayDetail(id) {
      this.taskId = id;
      this.taskIdLis.push(id);
      this.showDetail = true;
    },
    handleNav(nav) {
      this.aliveKey = nav;
    },
    init() {
      if (this.type == 'dailyBox') {
        this.api = getDailyBoxLis;
        this.title = '个人收纳箱';
      } else if (this.type == 'taskMy') {
        this.api = getDailyMyself;
        this.title = '我参与的任务';
      }
      this.getData();
    },
    getData() {
      this.api().then(res => {
        this.$set(this, 'taskData', res.data.data);
      });
    },
  },
};
</script>

<style lang="less" scope>
.daily-mobile {
  height: 100%;
  overflow: hidden;
  width: 100%;
  nav {
    background-color: #fff;
    z-index: 10;
    flex-shrink: 0;
    flex-grow: 0;
    .tasktype-ul {
      justify-content: space-around;
      height: 3.75rem;
      align-items: flex-end;

      .tasktype-li {
        .broder-line {
          // height: 1.71rem;
          padding-bottom: 1.02rem;
          display: inline-block;
          background-size: cover;
          margin-right: 0.26rem;
          border-bottom: 0.17rem solid transparent;
        }
      }

      .aliveNav {
        color: #13bfff;
        .broder-line {
          border-bottom: 0.17rem solid #13bfff;
        }
      }
    }
    .screen-search {
      border-top: 0.09rem solid #e8ecef;
      padding: 0.6rem 0;

      .search {
        // margin-right: 1.28rem;
        // width: 26.37rem;
        width: 28.08rem;
        height: 2.56rem;
        border-radius: 0.17rem;
        // border:1px solid red;
        background: rgb(247, 248, 250) url('~@/assets/image/icon-progress/icon_search@2x.png') no-repeat 11.09rem
          0.68rem;
        background-size: 1.2rem 1.19rem;
        padding: 1rem;

        &::placeholder {
          opacity: 0.25;
          text-align: center;
          color: #c2c7d9;
          transform: translateX(0.6rem);
        }

        &:focus {
          &::placeholder {
            opacity: 0;
          }
          background-image: none;
        }
      }

      .screen {
        vertical-align: middle;
        width: 1.71rem;
        height: 1.71rem;
        background-size: cover;
        display: inline-block;
      }
    }
  }

  .task-ul {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    flex-shrink: 1;
    flex-grow: 1;
    margin: 0 1.02rem;
    .task-li {
      // width: 29.95rem;
      height: 3.41rem;
      width: 100%;
      background: #ffffff;
      border-radius: 0.34rem;
      margin-bottom: 0.68rem;
      overflow: hidden;
      justify-content: flex-start;
      .status-ico {
        margin-left: 1.02rem;
        width: 5.97rem;
        height: 1.88rem;
        border-radius: 0.34rem;
        justify-content: flex-start;
        img {
          vertical-align: middle;
          margin: 0 0.51rem;
          width: 1.19rem;
          height: 1.19rem;
        }

        span {
          vertical-align: middle;
        }
      }
      .title {
        margin-left: 0.51rem;
        width: 19.37rem;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
        font-size: 1.37rem;
        font-weight: 400;
        color: #334681;
      }
      .ico {
        margin-left: 0.68rem;
      }
    }
  }
}

.daily-top {
  overflow: hidden;
  position: absolute;
}
.daily-auto-height {
  height: auto !important;
}
.daily-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
</style>

<template>
  <div class="full" v-if="!showMyBox">
    <div class="blue-box"></div>
    <div class="flex-h white-box">
      <div class="head-draw">{{ lastName(userInfo.name) }}</div>
      <div class="name">{{ userInfo.name }}</div>
    </div>
    <div class="logout-box">
      <div class="logout-ico" @click="logOut"></div>
    </div>
    <div class="flex-w my-box">
      <div class="flex-w">
        <div class="ico daily-box"></div>
        <div class="daily-title">个人收纳箱</div>
      </div>
      <i class="el-icon-arrow-right" @click="toMyBox('dailyBox')"></i>
    </div>
    <div class="flex-w my-box">
      <div class="flex-w">
        <div class="ico task-my"></div>
        <div class="daily-title">我参与的任务</div>
      </div>
      <i class="el-icon-arrow-right" @click="toMyBox('taskMy')"></i>
    </div>
  </div>
  <myBox :type="type" @back="back" v-else></myBox>
</template>
<script>
import { getLogOutUrl } from '@/service/help';
import cookie from '@/utils/cookie';
import myBox from '@/components/mobile/daily/myBox';
export default {
  data() {
    return {
      showMyBox: false,
      type: 'dailyBox', //taskMy
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    back() {
      this.showMyBox = false;
    },
    toMyBox(type) {
      this.type = type;
      this.showMyBox = true;
    },
    logOut() {
      let beforeClose = (action, done) => {
        if (action === 'confirm') {
          getLogOutUrl().then(() => {
            cookie(document).remove('name');
            cookie(document).remove('account');
            cookie(document).remove('password');
            this.$store.commit('set', { type: 'isLogin', data: false });
            this.$router.push('/login');
            done();
            console.log(this.$router);
          });
        } else {
          done();
        }
      };
      this.$dialog.confirm({
        title: '标题',
        message: '弹窗内容',
        beforeClose,
      });
    },
  },
  components: {
    myBox,
  },
};
</script>
<style lang="less" scope>
.my {
  justify-content: flex-start !important;
}
.logout-box {
  position: fixed;
  width: auto;
  height: auto;
  font-size: 2rem;
  right: 1.6rem;
  top: 4.78rem;
}
.logout-ico {
  width: 1.73rem;
  height: 1.88rem;
  background-size: cover;
  background-image: url('~@/assets/image/mobile/icon_help_w.png');
}
.blue-box {
  width: 100%;
  background-image: url('~@/assets/image/mobile/my.png');
  background-size: cover;
  height: 21.9rem;
}
.white-box {
  .my-box;
  margin-top: -3.92rem;
  height: 10.24rem;
  @h: 7.33rem;
  .head-draw {
    width: @h;
    height: @h;
    line-height: 6.33rem;
    border: 0.5rem solid #ffffff;
    border-radius: 50%;
    font-size: 2.05rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    transform: translateY(-50%);
  }
  .name {
    font-size: 1.54rem;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 2.13rem;
    color: #334681;
    margin-top: 1.1rem;
    transform: translateY(-@h / 2);
  }
}
.my-box {
  margin: 1.02rem auto;
  width: 29.95rem;
  background: #fff;
  border-radius: 0.51rem;
  height: 4.69rem;
  justify-content: space-between !important;
}
.flex-w {
  .ico {
    margin-left: 1.02rem;
    margin-right: 0.51rem;
    width: 1.71rem;
    height: 1.71rem;
  }
  .daily-box {
    background-image: url('~@/assets/image/icon-daily/icon_gr_gr.png');
  }
  .task-my {
    background-image: url('~@/assets/image/icon-daily/icon_sjx_gr.png');
  }
  .el-icon-arrow-right {
    margin-right: 1.02rem;
    // color: #c2c7d9;
    // font-size: 1.19rem;
    // font-weight: 600;
    // &::before {
    //   content: '' \e6e0'';
    // }
  }
  .daily-title {
    font-size: 1.45rem;
    font-weight: 400;
    color: #8590b3;
  }
}
</style>
